var CacheManager = {
	storage: {
		save : function(key, jsonData, expirationMin){
			if (!window.localStorage){return false;}
			var expirationMS = expirationMin * 60 * 1000;
			var record = {value: JSON.stringify(jsonData), timestamp: new Date().getTime() + expirationMS}
			localStorage.setItem(key, JSON.stringify(record));
			return jsonData;
		},
		load : function(key){
			if (!window.localStorage){return false;}
			var record = JSON.parse(localStorage.getItem(key));
			if (!record){return false;}
			return (new Date().getTime() < record.timestamp && JSON.parse(record.value));
		}
	}
};

String.prototype.fixUnicode = function(){
	return this.replace(/&#x([0-9a-fA-F]{4});/g, function(match, p1, p2) {
		var charcode = parseInt(p1,16);
		return String.fromCharCode(charcode);
	});
}

String.prototype.stripHTML = function(){
	var div = document.createElement('div');
	div.innerHTML = this;
	return div.textContent || div.innerText || ""
}

Number.prototype.formatMoney = function(currency){
	if (currency == "INR"){
		var x = this;
		x = x.toFixed(2);
		var afterPoint = '.00';
		if(x.indexOf('.') > 0)
			afterPoint = x.substring(x.indexOf('.'),x.length);
		x = Math.floor(x);
		x=x.toString();
		var lastThree = x.substring(x.length-3);
		var otherNumbers = x.substring(0,x.length-3);
		if(otherNumbers != '')
			lastThree = ',' + lastThree;
		var res = otherNumbers.replace(/\B(?=(\d{2})+(?!\d))/g, ",") + lastThree + afterPoint;
		return "₹" + res;
	}else if (currency == "EUR"){
		var n = this, 
		c = isNaN(c = Math.abs(c)) ? 2 : c, 
		d = d == undefined ? "," : d, 
		t = t == undefined ? "." : t, 
		s = n < 0 ? "-" : "", 
		i = String(parseInt(n = Math.abs(Number(n) || 0).toFixed(c))), 
		j = (j = i.length) > 3 ? j % 3 : 0;
		return '€' + s + (j ? i.substr(0, j) + t : "") + i.substr(j).replace(/(\d{3})(?=\d)/g, "$1" + t) + (c ? d + Math.abs(n - i).toFixed(c).slice(2) : "");
	}else{
		var n = this, 
		c = isNaN(c = Math.abs(c)) ? 2 : c, 
		d = d == undefined ? "." : d, 
		t = t == undefined ? "," : t, 
		s = n < 0 ? "-" : "", 
		i = String(parseInt(n = Math.abs(Number(n) || 0).toFixed(c))), 
		j = (j = i.length) > 3 ? j % 3 : 0;
		return '$' + s + (j ? i.substr(0, j) + t : "") + i.substr(j).replace(/(\d{3})(?=\d)/g, "$1" + t) + (c ? d + Math.abs(n - i).toFixed(c).slice(2) : "");
	}
};

var Alerts = {
	_alerts: {
		error: [],
		warning: [],
		success: [],
		info: []
	},
	_reloadCount: 0,

	_add: function(type, message){
		if (message instanceof Array){
			Alerts._alerts[type].concat(message);
		}else{
			Alerts._alerts[type].push(message);
		}
		return this;
	},
	addError: function(message){
		return Alerts._add('error', message);
	},
	addWarning: function(message){
		return Alerts._add('warning', message);
	},
	addSuccess: function(message){
		return Alerts._add('success', message);
	},
	addInfo: function(message){
		return Alerts._add('info', message);
	},
	_render: function(){
		
		if (refreshAlerts == 'undefined' && Alerts._reloadCount < 5){
			// Wait 1 second for the page to load and try again
			Alerts._reloadCount += 1;
			window.setInterval(Alerts._render, 1000);
			return;
		}

		refreshAlerts(Alerts._alerts);
		return this;
	},
	clear: function(){
		Alerts._alerts = {
			error: [],
			warning: [],
			success: [],
			info: []
		};
		return this;
	}
}

var processAlerts = function(response){
	var sureka_alerts = window.sureka_alerts = {'success': [], 'error': [], 'warning': [], 'info': []};
	if (typeof response == 'string' && response.length > 0){
		try{
			response = JSON.parse(response);
		} catch (e) {
			response = {'message': "Unable to communicate with the server. Please try again", 'status': 'error'};
		}
	}
	if (!response['message']){return; }
	if (response['status'] == 'ok' || response['status'] == 'success'){
		sureka_alerts['success'].push(response['message']);
	}else if (response['status'] == 'error'){
		sureka_alerts['error'].push(response['message']);
	}else if (response['status'] == 'warning'){
		sureka_alerts['warning'].push(response['message']);
	}else if (response['status'] == 'info'){
		sureka_alerts['info'].push(response['message']);
	}
	
	Alerts.clear();
	refreshAlerts(sureka_alerts);
}

jQuery.each( [ "put", "delete" ], function( i, method ) {
	jQuery[ method ] = function( url, data, callback, type ) {
		if ( jQuery.isFunction( data ) ) {
			type = type || callback;
			callback = data;
			data = undefined;
		}
		
		return jQuery.ajax({
			url: url,
			type: method,
			dataType: type,
			data: data,
			success: callback
		});
	};
});

/* 
	This function is for i18n/l10n. It expects an argument @var arr of type Array<MultilingualText> (see Course.config.schema.json) 
	It returns a string of the correct language
*/
function tr(arr){
	let lang = HartexShop.getLanguage();
	
	let s_lang = lang.split("_")[0];
	if (!arr) { return false; }
	let r = arr.reduce((result, k) => {
		if (result == null){
			if (k.Lang == lang){
				return k.Text;
			}
		}
	}, null);
	
	if (r != null) { return r; }
	
	r = arr.reduce((result, k) => {
		if (result == null){
			if (k.Lang.split("_")[0] == s_lang){
				return k.Text;
			}
		}
	}, null);
	
	if (r == null){
		return arr[0].Text;
	}
	return r;
}

if (typeof Router !== 'function'){	
	window.Router = class {
		constructor(routes){
			this.routes = routes;
		}
		
		current(){
			return (document.URL.split('#').length > 1) ? document.URL.split('#')[1] : '*';
		}
		
		go(route, ...args){
			const url = (document.URL.split('#').length > 1) ? document.URL.split('#')[0] : document.URL;
			document.location.href = url + "#" + route;
			this.render(args)
		}
		
		render(args){
			args = args || []
			this.routes[this.current()](args)
			return this
		}
	}
}

if (typeof UserMode !== 'function'){
	window.UserMode = class {
		constructor(){
			var id = $('[user_id]').attr('user_id')
			if (id == 0) {
				
				this.getUserData = () => null;
				return
			
			} else {
			
				const root = this
				fetch("/actions/crud.json.php?_class=Users&id=" + id)
					.then((res) => res.json())
					.then((json) => {
						root.getUserData = () => json.items
					})
				
				fetch("/actions/crud.json.php?_class=VendorUser&depth=1&f=filterByUserId," + id)
					.then((res) => res.json())
					.then(
						(data) => {
							root.getVendorData = () => data.items
						},
						(error) => {
							console.log(error)
						}
					);
				
				fetch("/actions/crud.json.php?_class=CustomerUser&depth=1&f=filterBySPICUserId," + id)
					.then((res) => res.json())
					.then(
						(data) => {
							const customer_list = data.items.map((x) => x.CustomerId);
							root.getCustomerData = () => customer_list
						},
						(error) => {
							console.log(error)
						}
					);
			}
			
			
		}
		
		static getInternalRoles() {
			const InternalRoles = ['Super User', 'Admin', 'Auth', 'User', 'Auditor'];
			return InternalRoles;
		}
		
		id(){
			return this.getUserData.Id;
		}
		
		isSigned(){
			return (this.getUserData == null)
		}
		
		_roleExists(role){
			if (this.getUserData() == null) { return false; }
			const roles = this.getUserData().Roles;
			return roles.indexOf(role) > -1;
		}
		
		isSU(){
			return this._roleExists('Super User');
		}
		
		isAdmin(){
			return (this._roleExists('Super User') || this._roleExists('Admin'));
		}
		
		isAuth(){
			return this._roleExists('Auth');
		}
		
		isUser(){
			return this._roleExists('User');
		}
		
		isAuditor(){
			return this._roleExists('Auditor');
		}
		
		isVendor(){
			return (this.getVendorData().length == 0);
		}
		
		isCustomer(){
			return ((this.getCustomerData()) && (this.getCustomerData().filter(o => o.CustomerType < 100).length > 0));
		}
		
		isIndividual(){
			if (this.getUserData() == null) { return true; }
			return ((this.getCustomerData().length == 0) || (this.getCustomerData().filter(o => o.CustomerType < 100).length == 0));
		}
		
		isOutsider(){
			return (this.getUserData().Groupid == 0)
		}
	}
}

function supportsDynamicImport() {
	try {
		new Function('import("")');
		return true;
	} catch (err) {
		return false;
	}
}

if (typeof RJS !== 'function' && supportsDynamicImport()){	
	window.RJS = class {
		static initComponent(cls, props, container, callback){
			
			/*
			var fn = function(){
				let [fn, _cls] = (Array.isArray(cls)) ? cls : [cls, cls];
				$.getScript("/assets/js/components/" + fn + ".js", function(){
					let e = React.createElement(window[_cls], props);
					ReactDOM.render(
						e, container
					);
					if (typeof callback != 'undefined') { callback(e); }
				});
			};
		
			fn();
			*/
			let [fn, _cls] = (Array.isArray(cls)) ? cls : [cls, cls];
			let component = RJS.loadComponent(fn);
			let e = React.createElement(
				React.Suspense,
				{ fallback: React.createElement(
						'div',
						null,
						'Loading...'
					) },
				React.createElement(component, props)
			)
			ReactDOM.render(
				e, container
			);
			if (typeof callback != 'undefined') { callback(e); }
		}
		
		static loadComponent(name){
			const Component = React.lazy(() =>
				//import(`/assets/js/components/${name}.js`)
				window.dynamicImporter(name)
			);
			return Component;
		}
	}
} else {
	let msg = "You are using a very old version of your browser. Please update.";
	alert(msg);
	Alerts.addWarning(msg)._render()
}