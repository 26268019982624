(function ($) {

	var methods = {
		init: function (options) {
			var target = this;
			var settings = $.extend({}, $.fn.tableInput.defaults, options);
			target.data('tableInput', settings);

			var totalWidth = settings['columns'].map(function (e, i) {
				return settings['columns'][i]['width'];
			}).reduce(function (acc, val) { return acc + val; }, 0);

			if (totalWidth > 12) {
				target.addClass('flexify');
			}

			$(this).empty().append("<thead><tr><th></th></tr></thead><tbody></tbody><tfoot><tr></tr></tfoot>").addClass('table').addClass('table-bordered').addClass('table-hover').addClass('table-striped');
			let $head = $(this).find("thead tr");
			let $body = $(this).find('tbody');
			let $tfoot = $(this).find('tfoot tr');
			$.each(settings['columns'], function (i, e) {

				if (e.editable == false) { return; }
				let $th = $("<th>" + e.display + "</th>");//.attr('name', e.name).attr('type', e.type);	// Commented out for required quantity in Purchase Orders
				$head.append($th);
			});

			$tfoot.append("<td><button class='btn btn-success add' type='button'><i class='fa fa-plus'></i></button></td>");
			$tfoot.find("td").attr('colspan', settings['columns'].length + 1);

			$body.on('click', 'tr td button.delete', function () {
				let tr = $(this).parents('tr');
				tr.fadeOut(function () { tr.remove() });
			});

			$tfoot.on('click', 'td button.add', function () {
				let o = $(this).parents('table');
				methods['addRow'](o);
			});

			if (settings['initRows']) {
				for (var s = settings['initRows']; s > 0; s--) {
					methods['addRow'](target);
				}
			}

			return this;

		},
		addRow: function (o) {
			var settings = o.data('tableInput');
			var $body = $(o).find("tbody");
			var tr = $('<tr><td><button class="btn btn-danger delete" type="button"><i class="fa fa-trash">&nbsp;</i></button><input type="hidden" class="form-control gv-tableinput-item" name="Id" /></td></tr>').appendTo($body);

			var td = settings['columns'].map(function (e, i) {

				var z = methods['_renderField'](settings['columns'][i]);

				if (e.editable == false) {
					//tr.find("td:first").append(z);	// Append it to the first element;
					return null;
				} else {
					var $td = $("<td></td>");
					$td.attr("title", settings['columns'][i]['display']);
					$td.width(((settings['columns'][i].width / 12.0) * 100) + "%");
					return $td.append(z);
				}
			});
			$.each(td, function (i, e) {
				if (e !== null) {
					$(e).appendTo(tr);
				}
			});
			tr.find("select,[gv-generated-remote]").trigger('domload');
		},
		_renderField: function (col) {
			var root = this;
			var o = null;
			var $p, v, $prep, $cont, fc, opts, updateFcItem;

			if (col['type'] == 'text') {
				o = $("<input type='text' name='" + col['name'] + "'></input>").addClass('form-control').addClass("gv-tableinput-item");

				if (col['prefixes']) {

					fc = $("<input class='gv-tableinput-item' type='hidden' name='" + col['name'] + "'/>").val(col['prefixes'][0]);
					updateFcItem = function () {
						fc.val(fc.siblings(".input-group-prepend").data("value") + fc.siblings("input[type=text]").val());
					}
					o.removeClass("gv-tableinput-item").attr("name", "d_" + col['name']).on("keyup paste", updateFcItem);

					$prep = $('<div class="input-group-prepend"><button class="btn btn-secondary dropdown-toggle" type="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">' + col['prefixes'][0] + '</button><div class="dropdown-menu"></div></div>');
					$prep.data("value", col['prefixes'][0]);
					$.each(col['prefixes'], function (i, o) {
						$prep.find(".dropdown-menu").append($('<a class="dropdown-item">' + o + '</a>'));
					});

					$prep.find("a.dropdown-item").on("click", function (e) {
						$p = $(e.target).closest(".input-group-prepend");
						$p.data("value", $(this).text()).find("button").text($(this).text());
						updateFcItem();
					});

					fc.on("change", function () {
						v = $(this).val();
						let $sel = $(this);
						$(this).closest(".input-group").find(".input-group-prepend .dropdown-item").each(function (i, o) {
							if (v.startsWith($(o).text())) {
								$(o).click();
								var w = v.substr($(o).text().length);
								fc.siblings(".form-control").val(w);
								$sel.val(v);
							}
						});
					});

					$cont = $("<div class='input-group gv-text'></div>");

					$cont.append($prep).append(o).append(fc);
					o = $cont;
				}

				if (col['suffixes']) {
					fc = $("<input class='gv-tableinput-item' type='hidden' name='" + col['name'] + "'/>").val(col['suffixes'][0]);
					updateFcItem = function () {
						fc.val(fc.siblings(".input-group-append").data("value") + fc.siblings(".form-control").val());
					}
					o.removeClass("gv-tableinput-item").attr("name", "d_" + col['name']).on("keyup paste", updateFcItem);

					$prep = $('<div class="input-group-append"><button class="btn btn-secondary dropdown-toggle" type="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">' + col['suffixes'][0] + '</button><div class="dropdown-menu"></div></div>');
					$prep.data("value", col['suffixes'][0]);

					$.each(col['suffixes'], function (i, o) {
						$prep.find(".dropdown-menu").append($('<a class="dropdown-item">' + o + '</a>'));
					});

					$prep.find("a.dropdown-item").on("click", function (e) {
						$p = $(e.target).closest(".input-group-append");
						$p.data("value", $(this).text()).find("button").text($(this).text());
						updateFcItem();
					});

					fc.on("change", function () {
						v = $(this).val();
						let $sel = $(this);
						$(this).closest(".input-group").find(".input-group-append .dropdown-item").each(function (i, o) {
							if (v.startsWith($(o).text())) {
								$(o).click();
								var w = v.substr(0, v.length - $(o).text().length);
								fc.siblings(".form-control").val(w);
								$sel.val(v);
							}
						});

					});

					$cont = $("<div class='input-group gv-text'></div>");

					$cont.append(o).append($prep).append(fc);
					o = $cont;
				}

			} else if (col['type'] == 'date') {
				o = $("<div class='input-group date'></div>");
				o.attr("name", "d_" + col['name']);
				o.append($('<div class="input-group-append"></div>').append($('<div class="input-group-text"></div>').append($("<i class='fa fa-calendar'></i>"))));

				var $inp = $("<input type='text' name='" + col['name'] + "'></input>").addClass('form-control date gv-tableinput-item');
				$inp.addClass("float-sm-right date gv-date").prop('required', !!col['required']);
				o.append($inp);

				$.fn.datepicker.defaults.format = 'dd-mm-yyyy';
				$inp.datepicker();
				$inp.prop('disabled', !!col['disabled']);

			} else if (col['type'] == 'number') {
				if (col['unit'] != undefined) {
					var attrs = [];
					if (col['min'] != undefined) {
						attrs.push(' min="' + col['min'] + '"');
					}
					if (col['max'] != undefined) {
						attrs.push(' max="' + col['max'] + '"');
					}
					if (col['step'] != undefined) {
						attrs.push(' step="' + col['step'] + '"');
					}
					o = $("<div class='input-group'><input type='number' name='" + col['name'] + "' " + attrs.join('') + "></input><div class='input-group-append'><span class='input-group-text'>" + col['unit'] + "</span></div></div>");
					o.find('input').addClass('form-control gv-tableinput-item');
				} else {
					o = $("<input type='number' name='" + col['name'] + "'></input>").addClass('form-control gv-tableinput-item');
				}
			} else if (col['type'] == 'currency') {

				o = $("<div class='input-group currency'><div class='input-group-prepend'><span class='input-group-text'>" + (col['unit'] || "<i class='fa fa-rupee-sign'></i>") + "</span></div><input class='pull-right gv-currency text-right' type='number' name='" + col['name'] + "' min='0' step='0.01'></input></div>");
				o.find('input').addClass('form-control gv-tableinput-item');
				o.attr("name", "d_" + col['name']);
			} else if (col['type'] == 'select') {
				opts = $.map(col['options'], function (t, i) { return "<option value='" + i + "'>" + t + "</option>"; })
				o = $("<select name='" + col['name'] + "'>" + opts.join('') + "</select>").addClass('form-control gv-tableinput-item');
			} else if (col['type'] == 'checkbox') {
				opts = $.map(col['options'], function (t, i) { return "<li class='list-group-item'><input type='checkbox' name='" + col['name'] + "' value='" + i + "' class='form-control gv-tableinput-item'/>&nbsp;" + t + "</li>"; })
				o = $("<ul class='list-group'>" + opts.join('') + "</ul>");
				o.attr("name", "d_" + col['name']);
			} else if (col['type'] == 'select2') {
				o = $("<select name='" + col['name'] + "' " + (col['multiple'] ? 'multiple' : '') + "></select>").addClass('form-control gv-select2 select2 gv-tableinput-item');

				var s2opts = {
					escapeMarkup: function (markup) { return markup; }, // let our custom formatter work
					minimumInputLength: 1
				};

				if (col['remote']) {
					o.attr('ajax', col['remote']);	// Needed for setField later

					s2opts['ajax'] = {
						url: col['remote'],
						dataType: 'json',
						delay: 250,
						placeholder: 'Select One...',
						data: function (params) {
							return {
								q: params.term,	// search term
								page: params.page
							};
						},
						processResults: function (data, params) {
							params.page = params.page || 1;

							return {
								results: data.items,
								pagination: {
									more: (params.page * 30) < data.total_count
								}
							};
						},
						cache: true
					}
				}

				o.one('domload', function (e) {
					o.select2(s2opts);
				});
			} else if (col['type'] == 'location') {

				let link = $('<button type="button" class="btn btn-primary btn-xs" data-toggle="modal" data-target="#edit-modal">Open</button>');
				o = $("<span><input type='hidden' class='form-control gv-tableinput-item' name='" + col['name'] + "'></input></span>");
				o.append(link);
				link.on('click', function (e) {
					let m = $("#edit-modal").modal();
					var $inp = $(this).siblings('input');
					$("#edit-modal").modal("openLocation", {
						'display': col['display'],
						'value': $inp.val(),
						'callback': function (v) { $inp.val(JSON.stringify(v)); }
					});
					$("#edit-modal").modal("show");
					e.stopPropagation();
				});
			}

			if (col['disabled'] == true) {
				if (o.is("input,select")) {
					o.prop('disabled', true);
				} else {
					o.find('input,select').prop('disabled', true);
				}
			}

			if (col['editable'] == false) {
				o.hide();
			}

			if (col['required']) {
				if (o.is("input,select")) {
					o.prop('required', true);
				} else {
					o.find('input,select').prop('required', true);
				}
			}

			if (col['generated'] && col['generated']['remote']) {
				window.genCache = {};
				var loadGen = function (url, callback) {
					if (!window.genCache[url]) {
						window.genCache[url] = $.get(url).promise();
					}
					window.genCache[url].done(callback);
				};

				if (col['generated']['editable'] !== true) {
					if (o.is("input,select")) {
						o.prop('disabled', true);
					} else {
						o.find('input,select').prop('disabled', true);
					}
				}

				if (col['generated']['readonly'] !== true) {
					if (o.is("input,select")) {
						o.prop('readonly', true);
					} else {
						o.find('input,select').prop('readonly', true);
					}
				}

				o.attr('gv-generated-remote', col['generated']['remote']).attr('gv-generated-variables', JSON.stringify(col['generated']['variables']));
				o.one('domload', function () {
					var variables = col['generated']['variables'];
					var url = col['generated']['remote'];

					var varz = $.map(variables, function (x) { return "[name^=" + x + "],[name=d_" + x + "]" }).join(",");

					var tr = o.closest('tr').on('change', varz, function (e) {
						var param_list = $.map(variables, (x) => o.closest('tr').find("[name^=" + x + "]").val())

						if (param_list.some(x => x.length == 0)) {
							// If any variables are not yet set, do not unnecesarily run loadGen
							// see: AdHocInvoice - Replacement L/R
							return;
						}

						param_list = $.map(param_list, (x, i) => variables[i] + "=" + encodeURIComponent(x)).join("&");;

						var glue = (url.indexOf("?") > -1) ? "&" : "?";
						loadGen(url + glue + param_list, function (response) {
							if (response['status'] == "ok") {
								if (response['items'] && o.hasClass('gv-select2')) {
									root.setField(o, response['items']);
								} else {
									let vv = response[col['name']];
									if (vv !== null && vv !== undefined) {
										root.setField(o, vv);	// Set the value
									}
								}
							} else {
								processAlerts(response);
							}
						});
					});
				});
			}
			return o;
		},
		serializeArray: function (o) {
			if (o == undefined) {
				o = this;
			}

			var objlist = [];

			$(o).find('tbody tr').each(function (i, e) {

				var obj = {};
				$(e).find('td .gv-tableinput-item').each(function (ii, ee) {
					obj[$(ee).attr('name')] = methods['getField']($(ee));
				});
				objlist.push(obj);
			});
			return objlist;
		},
		serializeJSON: function (o) {
			if (o == undefined) {
				o = this;
			}

			return JSON.stringify(methods['serializeArray'](o));
		},
		serialize: function (o) {
			if (o == undefined) {
				o = this;
			}

			return o.attr('name') + '=' + methods['serializeJSON'](o);
		},

		getField: function ($field) {
			if ($field.hasClass("gv-select2")) {
				var v = $field.select2("data");	// This returns a full object - that may be useful somewhere
				// However, the showIf change handlers from sureka.js:662 will do an indexOf on allowed_values
				// So it really needs something simple to work with

				v = v.map(function (o) { return o['id'] });

				if ((v.length == 1) && $field.prop('multiple') == false) {
					v = v[0];
				} else if (v.length == 0) {
					v = "";
				}
				return v;
			} else if ($field.hasClass('date')) {
				var value = $field.datepicker("getDate");
				var d_value = new Date(value);
				var v_date = luxon.DateTime.fromISO(d_value.toISOString())
				if (v_date.isValid) {
					value = v_date.toISODate();
					return value;
				} else {
					return null;
				}
			} else {
				return $field.val();
			}
		},

		setField: function ($field, value) {
			if ($field.hasClass("gv-select2")) {

				var addIfNotExists = function (id, text) {
					if ($field.find("option[value='" + id + "']").length) {
						$field.val(id);
					} else {
						// Create a DOM Option and pre-select by default
						var newOption = new Option(text, id, true, true);
						// Append it to the select
						$field.append(newOption);
						$field.val(id);
						$field.trigger('change');
					}
				};

				if (value === null) { return; }

				if (value instanceof Array) {
					$.each(value, function (i, o) {
						addIfNotExists(o.id, o.text);
					});
				} else if (typeof value == 'object' && value.id && value.text) {
					addIfNotExists(value.id, value.text);
				} else if ($field.attr("ajax")) {
					var url = $field.attr('ajax');
					var glue = (url.indexOf("?") > -1) ? "&" : "?";
					$.ajax({
						'type': 'GET',
						'url': url + glue + "id=" + value,
						'dataType': 'json'
					}).then(function (data) {
						if (data['items']) {
							data = data['items'];
						}
						addIfNotExists(data.id, data.text);
					});
				} else {
					$field.val(value);
				}
				$field.trigger('change');


			} else if ($field.hasClass('date')) {
				var v_date = window.luxon.DateTime.fromISO(value);

				if (v_date.isValid) {
					value = v_date.toISODate();
					$field.datepicker("setDate", new Date(value));
					$field.trigger('change');
				}
			} else if ($field.hasClass("currency")) {
				let o = $field.find("input[type=number]");
				o.val(value);
				$field.trigger("change");
			} else {
				if ($.isPlainObject(value) && $field.attr("type") == "hidden") {
					value = JSON.stringify(value);
				} else {
					console.log("Possible error in rendering data - please implement checkbox/location/currency/percentage handlers: ", $field, value);
				}

				$field.val(value);
				$field.trigger('change');
			}
		},

		setData: function (data) {
			console.trace(data);
			var o = this;
			$(o).find('tbody').empty();

			$.each(data, function (i, e) {

				let this_id = e['Id'] || false;
				var tr;

				if (this_id) {
					tr = o.find(`td [type=hidden][name=Id][value=${this_id}]`);
					console.log(tr);
					if (tr.length) {

					} else {
						methods['addRow'](o);
						tr = o.find('tbody tr:last');
					}
				} else {

					methods['addRow'](o);
					tr = o.find('tbody tr:last');

				}
				console.log(tr);
				$.each(e, function (k, v) {

					let $inp = tr.find('[name^=' + k + ']');
					console.log($inp, v);
					o.tableInput("setField", $inp, v);
				});
			});

		}
	};

	$.fn.tableInput = function (method) {
		if (methods[method]) {
			return methods[method].apply(this, Array.prototype.slice.call(arguments, 1));
		} else if (typeof method === 'object' || !method) {
			return methods.init.apply(this, arguments);
		} else {
			$.error('Method ' + method + ' does not exist on jQuery.tableInput');
		}
	};

}(jQuery));

$.fn.tableInput.defaults = {
	// Set default options here
	caption: "Table Input",
	columns: []
};
