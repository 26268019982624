//@ts-check

var GrapevineForm = function (options) {
	var vars = {};
	var fields = {};
	var gens = {};
	var container = null;
	var root = this;

	this.construct = function (options) {
		/*
		Initialize the value of the form
		*/
		$.extend(vars, options);
	}

	this._createLabel = function (key, text) {
		let $lbl = $("<label></label>");
		$lbl.attr('for', key).addClass("control-label").append(text).append($('<span class="gv-err">&nbsp;<span class="fa fa-exclamation-circle" aria-hidden="true"></span><span class="sr-only">Error</span></span>'));
		return $lbl;
	}

	this._createInput = function (name, type) {
		let $inp = $("<input></input>");
		$inp.attr('name', name).attr('type', type).addClass('form-control');
		return $inp;
	}

	this._createFunctions = {
		'container': function (key, def, value) {
			let $inp = $("<div class='gv-container'></div>").attr('name', key);
			root.add(key, $inp, value);
			$inp.isLoaded = true;
			return $inp;
		},
		'text': function (key, def, value) {
			var $inp = root._createInput(key, 'text');
			$inp.addClass('gv-text');
			$inp.attr('placeholder', def['display']);
			$inp.prop('disabled', !!def['disabled']);
			$inp.prop('readonly', !!def['readonly']);
			$inp.prop('required', !!def['required']);

			if (def['prefixes']) {
				var fc = $("<input type='hidden' name='" + key + "'/>").val(def['prefixes'][0]);
				var updateFcItem = function () {
					fc.val(fc.siblings(".input-group-prepend").data("value") + fc.siblings("input[type=text]").val());
				}
				$inp.attr("name", "d_" + key).removeClass("gv-text").on("keyup paste", updateFcItem);

				let $prep = $('<div class="input-group-prepend"><button class="btn btn-secondary dropdown-toggle" type="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">' + def['prefixes'][0] + '</button><div class="dropdown-menu"></div></div>');
				$prep.data("value", def['prefixes'][0]);
				$.each(def['prefixes'], function (i, o) {
					$prep.find(".dropdown-menu").append($('<a class="dropdown-item">' + o + '</a>'));
				});

				$prep.find("a.dropdown-item").on("click", function (e) {
					let $p = $(e.target).closest(".input-group-prepend");
					$p.data("value", $(this).text()).find("button").text($(this).text());
					updateFcItem();
				});

				fc.on("change", function () {
					let v = $(this).val();
					$(this).closest(".input-group").find(".input-group-prepend .dropdown-item").each(function (i, o) {
						if (v.startsWith($(o).text())) {
							$(o).click();
							v = v.substr($(o).text().length);
							fc.siblings(".form-control").val(v);
						}
					});
				});

				let $cont = $("<div class='input-group gv-text'></div>");

				$cont.append($prep).append($inp).append(fc);
				$inp = $cont;
			}

			if (def['suffixes']) {
				var fc = $("<input type='hidden' name='" + key + "'/>").val(def['suffixes'][0]);
				var updateFcItem = function () {
					fc.val(fc.siblings(".input-group-append").data("value") + fc.siblings("input[type=text]").val());
				}
				$inp.attr("name", "d_" + key).removeClass("gv-text").on("keyup paste", updateFcItem);

				let $prep = $('<div class="input-group-append"><button class="btn btn-secondary dropdown-toggle" type="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">' + def['prefixes'][0] + '</button><div class="dropdown-menu"></div></div>');
				$prep.data("value", def['suffixes'][0]);
				$.each(def['suffixes'], function (i, o) {
					$prep.find(".dropdown-menu").append($('<a class="dropdown-item">' + o + '</a>'));
				});

				$prep.find("a.dropdown-item").on("click", function (e) {
					let $p = $(e.target).closest(".input-group-append");
					$p.data("value", $(this).text()).find("button").text($(this).text());
					updateFcItem();
				});

				fc.on("change", function () {
					let v = $(this).val();
					$(this).closest(".input-group").find(".input-group-append .dropdown-item").each(function (i, o) {
						if (v.startsWith($(o).text())) {
							$(o).click();
							v = v.substr($(o).text().length);
							fc.siblings(".form-control").val(v);
						}
					});
				});

				let $cont = $("<div class='input-group gv-text'></div>");

				$cont.append($prep).append($inp).append(fc);
				let $inp = $cont;
			}

			root.add(key, $inp, value);
			$inp.isLoaded = true;
			return $inp;
		},
		'textarea': function (key, def, value) {
			let $inp = $("<textarea class='form-control gv-textarea'></textarea>");
			$inp.attr('name', key).attr('placeholder', def['display']).attr('rows', def['height'] || 5).prop('required', !!def['required']);
			$inp.prop('disabled', !!def['disabled']);
			$inp.prop('readonly', !!def['readonly']);
			root.add(key, $inp, value);
			$inp.isLoaded = true;
			return $inp;
		},
		'wysiwyg': function (key, def, value) {
			let $inp = $("<div class='form-control gv-wysiwyg'></textarea>");
			$inp.attr('name', key).attr('placeholder', def['display']).height((def['height'] || 5) + "rem").prop('required', !!def['required']);
			$inp.prop('disabled', !!def['disabled']);
			$inp.prop('readonly', !!def['readonly']);
			root.add(key, $inp, value);

			if (!($inp.prop('disabled') || $inp.prop("readonly"))) {
				InlineEditor
					.create($inp.get(0))
					.then(editor => {
						$inp.data("editor", editor);
						editor.model.document.on('change:data', () => {
							$inp.trigger("change");
						});
						$inp.isLoaded = true;
						root.checkOnLoad();
					})
					.catch(error => {
						console.error(error);
					});


				$inp.on('focus', function () {
					window.tc.disable();
				}).on('blur', function () {
					window.tc.enable();
				})
			}

			return $inp;
		},
		'code': function (key, def, value) {

			let $inp = $("<div class='gv-code'></div>");
			$inp.attr('name', key).attr('placeholder', def['display']).height((def['height'] || 5) + "rem").prop('required', !!def['required']);
			$inp.prop('disabled', !!def['disabled']);
			$inp.prop('readonly', !!def['readonly']);
			if (def['language']) {
				$inp.addClass("language-" + def['language']);
			}

			root.add(key, $inp, value);

			const highlight = editor => {
				//editor.textContent = editor.textContent
				//let opts = {};
				//if (def['language']) { opts['languages'] = def['language']; }
				//console.log(opts);
				//hljs.configure(opts);
				hljs.highlightElement(editor);
			}

			$.getScript("/plugins/codejar/codejar.js", function () {
				if (!($inp.prop('disabled') || $inp.prop("readonly"))) {
					let jar = CodeJar($inp.get(0), highlight);
					$inp.data("code", jar);

					jar.onUpdate(code => {
						$inp.trigger('change');
					});

					$inp.on('focus', function () {
						window.tc.disable();
					}).on('blur', function () {
						window.tc.enable();
					});
					$inp.isLoaded = true;
					root.checkOnLoad();
				}
			});


			return $inp;
		},
		'radio': function (key, def, value) {
			let $inp = $("<div></div>");
			$inp.attr("name", "d_" + key);
			$inp.addClass("gv-radio");
			$.each(def['options'], function (v, o) {
				let $r = $("<div class='radio'><label><input type='radio' class='input-control' name='" + key + "' value='" + v + "'></input>&nbsp;&nbsp;" + o + "</label></div>").prop('required', !!def['required']);
				$inp.append($r);
			});
			if (def['default'] != undefined) {
				$inp.find("input[type=radio][value='" + def['default'] + "']").prop('checked', true);
			}
			$inp.find('input').prop('disabled', !!def['disabled']);
			$inp.find('input').prop('readonly', !!def['readonly']);
			root.add(key, $inp, value);
			$inp.isLoaded = true;

			return $inp;
		},
		'checkbox': function (key, def, value) {
			let $inp = $("<div></div>");
			$inp.attr("name", "d_" + key);
			$inp.addClass('gv-checkbox');
			$.each(def['options'], function (v, o) {
				let $r = $("<div class='radio'><label><input type='checkbox' class='input-control' name='" + key + "[]' value='" + v + "'></input>&nbsp;&nbsp;" + o + "</label></div>").prop('required', !!def['required']);
				$inp.append($r);
			});

			if (def['default'] != undefined) {
				var d_default = def['default'];
				if (!$.isArray(d_default)) {
					d_default = [d_default];
				}
				$.each(d_default, function (i, o) {
					$inp.find("input[type=radio][value='" + o + "']").prop('checked', true);
				});

			}
			$inp.find('input').prop('disabled', !!def['disabled']);
			$inp.find('input').prop('readonly', !!def['readonly']);
			root.add(key, $inp, value);
			$inp.isLoaded = true;
			return $inp;
		},
		'select': function (key, def, value) {
			let $inp = $("<select class='form-control gv-select'></select>").prop('required', !!def['required']);
			$inp.attr('name', key);

			if (!!def['multiple']) {
				$inp.prop('multiple', true);
				$inp.attr('name', key + '[]');
			} else {
				$inp.append("<option value=''>Select one...</option>");
			}

			$.each(def['options'], function (v, o) {

				if ($.isPlainObject(o)) {
					let og = $("<optgroup></optgroup>").attr('label', v);
					$.each(o, function (vv, oo) {
						let $r = $("<option></option>").attr('value', vv).append(oo);
						og.append($r);
					});
					$inp.append(og);
				} else {
					let $r = $("<option></option>").attr('value', v).append(o);
					$inp.append($r);
				}

			})

			if (def['default'] != undefined) {
				$inp.val(def['default']);
			}

			$inp.prop('disabled', !!def['disabled'] || !!def['readonly']);

			root.add(key, $inp, value);
			$inp.isLoaded = true;
			return $inp;
		},
		'location': function (key, def, value) {
			let $cont = $("<div></div>");
			$cont.attr("name", "d_" + key);
			let $inp = $("<div></div>").attr({ 'id': key, 'name': key }).addClass('location gv-location').css({ 'width': '500px', 'height': '400px' });
			var $inputBinding = null;
			if (def['address_text'] > 0) {
				$inputBinding = root._createInput(key + "_address", 'text');
				$cont.append($inputBinding);
			}
			$cont.append($inp);
			$cont.append("<small>Click and drag the marker to the correct location</small>");

			var initFunc = function () {
				value = value || { latitude: 22.547308, longitude: 88.357379 };
				value['latitude'] = value['latitude'] || value['lat'];
				value['longitude'] = value['longitude'] || value['lng'];

				let location_options = {
					location: value,
					radius: 100,
					onchanged: function (currentLocation, radius, isMarkerDropped) {
						// This is not triggered when the location is changed programmatically
						$inp.trigger('change');
					},
					oninitialized: function (component) {
						$inp.isLoaded = true;
						root.checkOnLoad();
					}
				};

				if (def['address_text']) {
					location_options['inputBinding'] = { 'locationNameInput': $inputBinding };
					location_options['enableAutocomplete'] = true;
				}

				$inp.locationpicker(location_options);

			};

			// The root.add must come before initFunc so that the event handlers are correctly assigned
			root.add(key, $inp);

			if (window.gv_locationpickerloaded) {
				initFunc();
			} else {
				$(window).one("gv:locationpickerload", initFunc);
			}

			return $cont;
		},
		'date': function (key, def, value) {
			let $cont = $("<div></div>");
			$cont.attr("name", "d_" + key);

			$cont.addClass('input-group date');
			$cont.append($('<div class="input-group-append"></div>').append($('<div class="input-group-text"></div>').append($("<i class='fa fa-calendar'></i>"))));
			let $inp = root._createInput(key, 'text');
			$inp.addClass("float-sm-right date gv-date").prop('required', !!def['required']);
			$cont.append($inp);
			// We have to use datepicker for cross browser compatibility - Safari on MacOS doesnt handle input[type=date] very well
			$.fn.datepicker.defaults.format = 'dd-mm-yyyy';
			$inp.datepicker(def['dt_options'] ?? {});
			$inp.prop('disabled', !!def['disabled']);
			$inp.prop('readonly', !!def['readonly']);
			root.add(key, $inp, value);
			$inp.isLoaded = true;
			return $cont;
		},
		'currency': function (key, def, value) {
			let $cont = $("<div></div>");
			$cont.attr("name", "d_" + key);

			$cont.addClass('input-group currency');

			$cont.append(
				$('<div class="input-group-prepend"></div>').append(
					$('<span class="input-group-text"></span>').append(
						def['unit'] || $("<i class='fa fa-rupee-sign'></i>")
					)
				)
			);

			let $inp = root._createInput(key, 'number');
			$inp.addClass("float-sm-right gv-currency");
			$inp.attr({ 'step': '0.01', 'min': 0 }).css({ 'text-align': 'right' }).prop('required', !!def['required']);
			$cont.append($inp);
			$inp.prop('disabled', !!def['disabled']);
			$inp.prop('readonly', !!def['readonly']);
			root.add(key, $inp, value);
			$inp.isLoaded = true;
			return $cont;
		},
		'percentage': function (key, def, value) {
			let $cont = $("<div></div>");
			$cont.attr("name", "d_" + key);

			$cont.addClass('input-group percentage');

			let $inp = root._createInput(key, 'number');
			$inp.addClass("float-sm-right gv-percentage");
			$inp.attr({
				'step': def['step'] || '0.01',
				'min': def['min'] || 0,
				'max': def['max'] || 100
			})
				.css({ 'text-align': 'right' })
				.prop('required', !!def['required']);
			$cont.append($inp);
			$cont.append($('<div class="input-group-append"></div>').append($('<div class="input-group-text"></div>').append($("<i class='fa fa-percent'></i>"))));
			$inp.prop('disabled', !!def['disabled']);
			$inp.prop('readonly', !!def['readonly']);
			root.add(key, $inp, value);
			$inp.isLoaded = true;
			return $cont;
		},
		'number': function (key, def, value) {
			let $cont = $("<div></div>");
			$cont.attr("name", "d_" + key);

			$cont.addClass('input-group number');

			let $inp = root._createInput(key, 'number');
			$inp.addClass("float-sm-right gv-number");

			var attrs = {};
			if (def['min']) attrs['min'] = def['min'];
			if (def['max']) attrs['max'] = def['max'];
			if (def['step']) attrs['step'] = def['step'];
			if (def['placeholder']) attrs['placeholder'] = def['placeholder'];

			$inp.css({ 'text-align': 'right' }).attr(attrs).prop('required', !!def['required']);
			$cont.append($inp);

			if (def['unit']) {
				$cont.append($('<div class="input-group-append"></div>').append($('<span class="input-group-text"></span>').append(def['unit'])));
			}
			$inp.prop('disabled', !!def['disabled']);
			$inp.prop('readonly', !!def['readonly']);
			root.add(key, $inp, value);
			$inp.isLoaded = true;
			return $cont;
		},
		'select2': function (key, def, value) {
			let $cont = $("<div class='gv-select2-group'></div>");
			let $sel = $("<select class='form-control gv-select2 select2'></select>");
			var okey = key;
			if (!!def['multiple']) {
				okey += '[]';
			}
			$sel.attr('name', okey);
			$sel.attr('ajax', def['remote']);
			$sel.prop('required', !!def['required']);
			$sel.prop('multiple', !!def['multiple']);

			$.fn.select2.defaults.set("width", "100%");
			var options = { allowClear: true, placeholder: "Select One" };

			if (def['options']) {
				$.extend(options, { data: def['options'] });
			}

			if (def['remote']) {
				$.extend(options, {
					escapeMarkup: function (markup) { return markup; }, // let our custom formatter work
					minimumInputLength: 3,
					ajax: {
						url: def['remote'],
						dataType: 'json',
						delay: 250,
						placeholder: 'Select one...',
						data: function (params) {
							return {
								q: params.term,	// search term
								page: params.page
							};
						},
						processResults: function (data, params) {
							params.page = params.page || 1;

							return {
								results: data.items,
								pagination: {
									more: (params.page * 30) < data.total_count
								}
							};
						},
						cache: true
					}
				});
			}

			if (!!def['disabled'] || !!def['readonly']) {
				$.extend(options, { disabled: true });
			}

			if (def['template']) {
				let template = Handlebars.compile(def['template']);
				$.extend(options, {
					templateResult: function (state) {
						return template(state);
					}
				})
			}

			$cont.append($sel);

			$sel.select2(options);

			root.add(key, $sel, value);
			BarcodeHandler.subscribeSelect2("select[name^=" + key + "]", def['barcodes'] || []);
			$sel.isLoaded = true;
			return $cont;
		},
		'image': function (key, def, value, $c) {
			let $cont = $("<div class='gv-filer-group'></div>");
			if ($c.closest("form").attr("method") == 'CREATE') {
				$cont.append("<small>Please save the " + $c.closest("form").attr("object_class") + " before adding files</small>");
				$cont.isLoaded = true;
				root.add(key, $cont, null);
				return $cont;
			} else {
				Dropzone.options[key] = false;
				let $inp = $("<div name='" + key + "' class='gv-dropzone gv-image dropzone'></div>");
				$inp.prop("multiple", !!def['multiple']);
				$inp.prop("required", !!def['required']);
				$cont.append($inp);

				let server_data = {
					field: key,
					class: $c.closest('form').attr("object_class"),
					id: $c.closest('form').find("input[name=Id]").val(),
					nonce: $c.closest('form').find("input[name=nonce]").val()
				};

				var dz = $inp.dropzone({
					url: '/actions/filer/ajax_upload_file.php?' + $.param(server_data),
					paramName: key,
					maxFilesize: 25000,
					thumbnailWidth: 120,
					thumbnailHeight: 120,
					thumbnailMethod: 'contain',
					addRemoveLinks: true,
					acceptedFiles: "image/jpeg, image/gif, image/png, application/pdf",
					removedfile: function (file) {
						var name = file.name;

						$.ajax({
							type: 'POST',
							url: '/actions/filer/ajax_remove_file.php',
							data: {
								file: name,
								server_data: server_data,
								nonce: $c.closest("form").find('input[name=nonce]').val()
							}
						});
						var _ref;
						return (_ref = file.previewElement) != null ? _ref.parentNode.removeChild(file.previewElement) : void 0;
					}
				});

				root.add(key, $inp);
				$inp.isLoaded = true;

				if (def['canva']) {
					let $canvaBtn = $("<button class='btn btn-default' type='button' style='background-color:#7d2ae8;color#fff;font-weight:bold;'>Design on Canva</button>");
					$canvaBtn.on("click", function () {
						(function (document, url) {
							var script = document.createElement('script');
							script.src = url;
							script.onload = function () {
								(async function () {
									if (window.Canva && window.Canva.DesignButton) {
										const api = await window.Canva.DesignButton.initialize({
											apiKey: 'ydiHCZ2xOl00GDxed-jRANmf',
										});
										// Use "api" object or save for later

										api.createDesign({
											design: {
												type: "Poster",
											},
											onDesignPublish: ({ exportUrl, designId }) => {

												var blob = null;
												var xhr = new XMLHttpRequest();
												xhr.open("GET", exportUrl);
												xhr.responseType = "blob";
												xhr.onload = function () {
													blob = xhr.response;
													blob = new File([blob], designId + ".png", { 'type': 'image/png', 'canvaDesignId': designId });
													dz.get(0).dropzone.addFile(blob);
												}
												xhr.send();
											},
										});
									}
								})();

							};
							document.body.appendChild(script);
						})(document, 'https://sdk.canva.com/designbutton/v2/api.js');
					});

					$cont.append($canvaBtn);
				}

				return $cont;
			}
		},
		'file': function (key, def, value, $c) {
			let $cont = $("<div class='gv-filer-group'></div>");
			if ($c.closest("form").attr("method") == 'CREATE') {
				$cont.append("<small>Please save the " + $c.closest("form").attr("object_class") + " before adding files</small>");
				$cont.isLoaded = true;
				root.add(key, $cont, null);
				return $cont;
			} else if (def['plugin'] && def['plugin']['src'] && typeof def['plugin']['src'] == 'string') {

				$.get(def['plugin']['src'], function (script, status) {

					$.globalEval(script['result']);
					window[def['plugin']['init']]($cont.get(0));
				})
				$cont.isLoaded = true;
				root.add(key, $cont);
				return $cont;
			} else {
				Dropzone.options[key] = false;
				let $inp = $("<div name='" + key + "' class='gv-dropzone gv-file dropzone'></div>");
				$inp.prop("multiple", !!def['multiple']);
				$inp.prop("required", !!def['required']);
				$cont.append($inp);

				let server_data = {
					field: key,
					class: $c.closest('form').attr("object_class"),
					id: $c.closest('form').find("input[name=Id]").val(),
					nonce: $c.closest('form').find("input[name=nonce]").val()
				};

				var dz = $inp.dropzone({
					url: '/actions/filer/ajax_upload_file.php?' + $.param(server_data),
					paramName: key,
					maxFilesize: 25000,
					thumbnailWidth: 120,
					thumbnailHeight: 120,
					thumbnailMethod: 'contain',
					addRemoveLinks: true,
					removedfile: function (file) {
						var name = file.name;

						$.ajax({
							type: 'POST',
							url: '/actions/filer/ajax_remove_file.php',
							data: {
								file: name,
								server_data: server_data,
								nonce: $c.closest("form").find('input[name=nonce]').val()
							}
						});
						var _ref;
						return (_ref = file.previewElement) != null ? _ref.parentNode.removeChild(file.previewElement) : void 0;
					}
				});

				root.add(key, $inp);
				$inp.isLoaded = true;
				return $cont;
			}
		},
		'table': function (key, def, value) {
			let $cont = $("<div class='gv-table-group'></div>");
			$cont.attr("name", "d_" + key);

			let $inp = $("<table id='" + key + "' name='" + key + "'></table>");
			$inp.addClass("gv-table");
			$cont.append($inp);

			$inp.tableInput({
				caption: def['display'],
				initRows: 0,
				columns: def['columns']
			});
			root.add(key, $inp, value);
			$inp.isLoaded = true;
			return $cont;
		},
		'object': function (key, def, value) {
			let defs = def['contents'];
			let $cont = $("<div class='gv-object-contents' name='" + key + "'>");
			root.add(key, $cont, value);
			value = value || {};

			root.setDefinitions(defs, value, $cont);

			$cont.isLoaded = true;
			return $cont;
		},
		'rrule': function (key, def, value) {
			let $cont = $("<div class='gv-rrule-group'><ul class='list-group'><li class='list-group-item'></li><li class='list-group-item'></li><li class='list-group-item'></li></ul></div>");

			let $inp = root._createInput(key, 'hidden');

			// Start Date
			let $start = root._createInput('dtstart', 'text');
			$start.addClass("date");
			$start.datepicker();

			// End Date
			let $end = root._createInput('until', 'text');
			$end.addClass("date");
			$end.datepicker();

			// Define all the year options
			let $year_options = $(`<div class='w-100' freq='0'>
													<input name='y_type' type='radio' value='simple'>&nbsp;</input>
													on <select name='bymonth' category='month' class='form-control m-2'></select>
													&nbsp;<select name='bymonthday' class='form-control m-2' category='date' month='bymonth'></select>
											   </div>
											   <div class='w-100' freq='0'>
													<input name='y_type' type='radio' value='complex'>&nbsp;</input>
													on the <select name='y_nth' class='form-control m-2'>
																<option value='1'>First</option>
																<option value='2'>Second</option>
																<option value='3'>Third</option>
																<option value='4'>Fourth</option>
																<option value='-1'>Last</option>
															</select>
													&nbsp;<select name='y_weekday' class='form-control m-2' category='dayweek'></select>
							 						of <select name='bymonth' class='form-control m-2' category='month'></select>
											   </div>`);

			$year_options.find("input[name=y_type]").eq(0).click();
			// End of year options definition

			// Start month options definition
			let $month_options = $(`<div class='w-100' freq='1'>every <input type='text' name='interval' class='form-control m-2' value='1'/> month(s)</div>
												<div class='w-100' freq='1'><input name='m_type' type='radio' value='simple'>&nbsp;</input>on day 
													<select name='bymonthday' class='form-control m-2'>
														<option value="1">1</option>
														<option value="2">2</option>
														<option value="3">3</option>
														<option value="4">4</option>
														<option value="5">5</option>
														<option value="6">6</option>
														<option value="7">7</option>
														<option value="8">8</option>
														<option value="9">9</option>
														<option value="10">10</option>
														<option value="11">11</option>
														<option value="12">12</option>
														<option value="13">13</option>
														<option value="14">14</option>
														<option value="15">15</option>
														<option value="16">16</option>
														<option value="17">17</option>
														<option value="18">18</option>
														<option value="19">19</option>
														<option value="20">20</option>
														<option value="21">21</option>
														<option value="22">22</option>
														<option value="23">23</option>
														<option value="24">24</option>
														<option value="25">25</option>
														<option value="26">26</option>
														<option value="27">27</option>
														<option value="28">28</option>
														<option value="29">29</option>
														<option value="30">30</option>
														<option value="31">31</option>
													</select></div>
												<div class='w-100' freq='1'>
													<input name='m_type' type='radio' value='complex' />&nbsp;on the 
													<select name='m_nth' class='form-control m-2'>
														<option value='1'>First</option>
														<option value='2'>Second</option>
														<option value='3'>Third</option>
														<option value='4'>Fourth</option>
														<option value='-1'>Last</option>
													</select>&nbsp;
													<select name='m_weekday' class='form-control m-2' category='dayweek'></select>
												</div>`);

			$month_options.find("input[name=m_type]").eq(0).click();
			// End of month options definition

			// Define all the week options
			let $week_options = $(`<div class='w-100' freq='2'>every <input type='text' name='interval' class='form-control m-2' value='1'/> week(s)</div>
												<div class='w-100' freq='2'>
													<div class="btn-group-toggle week" data-toggle="buttons">
														<style>
															.week label{
																display:inline-block;
															}
															.week > label.btn.btn-default:not(:disabled):not(.disabled).active{
																background-color: #0062cc;
																border-color: #005cbf; 
																color: #fff;
															}
														</style>
														<label class="btn btn-default">
															<input name="byweekday" type="checkbox" autocomplete="off" value="MO"> Mon
														</label>
														<label class="btn btn-default">
															<input name="byweekday" type="checkbox" autocomplete="off" value="TU"> Tue
														</label>
														<label class="btn btn-default">
															<input name="byweekday" type="checkbox" autocomplete="off" value="WE"> Wed
														</label>
														<label class="btn btn-default">
															<input name="byweekday" type="checkbox" autocomplete="off" value="TH"> Thu
														</label>
														<label class="btn btn-default">
															<input name="byweekday" type="checkbox" autocomplete="off" value="FR"> Fri
														</label>
														<label class="btn btn-default">
															<input name="byweekday" type="checkbox" autocomplete="off" value="SA"> Sat
														</label>
														<label class="btn btn-default">
															<input name="byweekday" type="checkbox" autocomplete="off" value="SU"> Sun
														</label>
													</div>
												</div>`);

			// End of year options definition

			// Frequency dropdow event handler to switch between option-sets
			let $repeat_type = $("<select name='freq' class='form-control'></select>");
			$repeat_type.append($("<option value='0'>Yearly</option><option value='1'>Monthly</option><option value='2'>Weekly</option>"));
			$repeat_type.on('change', function () {
				$cont.find(".rrule-options").find("[freq]").hide();
				$cont.find(".rrule-options").find("[freq=" + ($(this).val()) + "]").show();
			});

			// Construct the whole HTML structure
			$cont.find("li").eq(0).append($("<div class='row'><div class='col-4 text-right'><label>Start Date</label></div><div class='col-8'></div></div>")).find("div.col-8").append($start);
			$cont.find("li").eq(1).append($("<div class='row'><div class='col-4 text-right'><label>Repeat</label></div><div class='col-8'></div></div>")).find("div.col-8").append($repeat_type);
			$cont.find("li").eq(1).append($("<div class='row'><div class='col-4 text-right'> </div><div class='col-8 rrule-options form-inline'></div></div>")).find(".rrule-options").append($year_options, $month_options, $week_options);
			$cont.find("li").eq(2).append($("<div class='row'><div class='col-4 text-right'><label>End Date</label></div><div class='col-8'></div></div>")).find("div.col-8").append($end);

			// Build all the dropdowns
			$cont.find("select[category='month']").each(function (x, o) {
				var months = luxon.Info.months('short')

				for (var i = 0; i < months.length; i++) {
					var m = (i + 1) + "";
					m = m.padStart(2, '0');
					$(o).append($("<option value='" + m + "'>" + months[i] + "</option>"));
				}
			});

			$cont.find("select[category='date']").each(function (x, o) {
				var sel_month = $(o).attr("month");
				var $sel_month = $year_options.find("[name=" + sel_month + "]");

				$sel_month.on("change", function (e) {
					var d = luxon.DateTime.local((new Date()).getFullYear(), parseInt($(this).val(), 10))
					var $o = $(o).empty();
					for (var i = 1; i < d.daysInMonth + 1; i++) {
						$o.append($("<option value='" + i + "'>" + i + "</option>"));
					}
				});
				$sel_month.trigger('change');
			});

			$cont.find("select[category='dayweek']").each(function (x, o) {
				var dayweeks = luxon.Info.weekdays();

				for (var i = 0; i < dayweeks.length; i++) {
					var m = dayweeks[i].substr(0, 2).toUpperCase();
					$(o).append($("<option value='" + m + "'>" + dayweeks[i] + "</option>"));
				}
			});

			$cont.find("input[type=radio]").on('click', function (e) {
				$(this).closest(".rrule-options").find("input[type=radio]").closest("[freq]").find('select,input[type!=radio]').each(function (i, o) {
					$(o).prop('disabled', true);
				});

				$(this).parent().find("input,select").each(function (i, o) {
					$(o).prop('disabled', false);
				});
			});


			// Event handlers to update the master rrule input field
			$cont.find("input,select").on('change', function () {

				var dtstart = $cont.find("[name=dtstart]").datepicker('getDate');
				var until = $cont.find("[name=until]").datepicker('getDate');

				// Return an empty string if validation fails
				if (isNaN(until) || isNaN(dtstart)) {
					$inp.val("");
					return;
				}
				var options = {
					freq: $cont.find("[name=freq]").val(),
					interval: 1,
					dtstart: dtstart,
					until: until,
				}
				var $opt = $cont.find("[freq=" + options.freq + "]");
				var pvars = ['interval', 'bymonth', 'bymonthday', 'byday', 'bysetpos'];

				$.each(pvars, function (i, x) {
					let $i = $opt.find("[name='" + x + "']:not([disabled])");
					if ($i.length > 0) {
						options[x] = $i.val();
					}
				});

				// Handle special case of complex year type
				if (options.freq == 0 && $cont.find("input[name=y_type][value=complex]").is(":checked")) {
					var y_weekday = $cont.find("[freq=0] [name=y_weekday]:not([disabled])").val();
					var y_nth = $cont.find("[freq=0] [name=y_nth]:not([disabled])").val();
					options['byweekday'] = [rrule.RRule[y_weekday].nth(y_nth)];
				}

				// Handle special case of complex month type
				if (options.freq == 1 && $cont.find("[freq=1] input[name=m_type][value=complex]").is(":checked")) {
					var m_weekday = $cont.find("[freq=1] [name=m_weekday]:not([disabled])").val();
					var m_nth = $cont.find("[freq=1] [name=m_nth]:not([disabled])").val();
					options['byweekday'] = [rrule.RRule[m_weekday].nth(m_nth)];
				}

				// Handle special case of complex week type
				if (options.freq == 2 && $cont.find("[freq=2] input[name=byweekday]:checked").length > 0) {
					var w_weekday = $cont.find("[freq=2] [name=byweekday]:checked").map(function (i, x) { return rrule.RRule[$(x).val()] });

					options['byweekday'] = $.makeArray(w_weekday);
				}

				var r = new rrule.RRule(options);

				$inp.val(r.toString());
			});

			$inp.on("change", function () {
				// Reflect any changes in the value to all the elements
				var options = rrule.RRule.parseString($(this).val());
				$cont.find("[name=dtstart]").datepicker("setDate", options.dtstart);
				$cont.find("[name=until]").datepicker("setDate", options.until);
				$cont.find("[name=freq]").val(options.freq).trigger("change");

				var $freq = $cont.find("[freq=" + options.freq + "]");
				for (let [key, value] of Object.entries(options)) {
					if (value === Object(value)) {
						if (options.freq == 0) {
							if (key == "byweekday") {
								$freq.find(`[name=y_nth]`).val(value[0]['n']);
								var day = luxon.Info.weekdays()[(value[0]['weekday'] + 1) % 7];
								$freq.find(`[name=y_weekday]`).val(day.substr(0, 2).toUpperCase());
								$freq.find(`[name=y_type][value=complex]`).click();
							}
						} else if (options.freq == 1) {
							if (key == "byweekday") {
								$freq.find(`[name=m_nth]`).val(value[0]['n']);
								var day = luxon.Info.weekdays()[(value[0]['weekday'] + 1) % 7];
								$freq.find(`[name=m_weekday]`).val(day.substr(0, 2).toUpperCase());
								$freq.find(`[name=m_type][value=complex]`).click();
							}
						} else if (options.freq == 2) {
							if (key == "byweekday") {
								$.each(value, function (i, o) {
									var day = luxon.Info.weekdays()[(o.weekday + 1) % 7];
									day = day.substr(0, 2).toUpperCase();
									$freq.find(`[name=byweekday][value=${day}]`).prop('checked', true).click();
								});
							}
						}
					} else {
						$freq.find(`[name='${key}']`).val(value);
					}
				}
				$freq.find()

			});

			$repeat_type.trigger('change');

			root.add(key, $inp);
			$inp.isLoaded = true;
			return $cont;
		}
	}

	this.setDefinitions = function (defs, request, $container) {
		root.defs = $.extend(root.defs || {}, defs);
		$.each(defs, function (key, def) {
			if (def['editable'] === false) {
				if (def['disabled'] !== true) {
					var $inp = root._createInput(key, 'hidden');
					$container.append($inp);
					root.add(key, $inp, request[key]);
					$inp.isLoaded = true;
				}
				return;
			}

			let $frmGroup = $("<div class='form-group col-sm-12'></div>").addClass('col-md-' + def['width']);

			if (!root._createFunctions[def['type']]){
				
				console.log(`Unable to create field: ${key} - no type found: ${def['type']}`)

			} else {
				let $o = root._createFunctions[def['type']] && root._createFunctions[def['type']](key, def, request[key], $container);

				if (def['help_text']) {
					$o.tooltip({ 'trigger': 'focus', 'title': def['help_text'] });
				}

				$frmGroup.append(root._createLabel(key, def['display']));
				$frmGroup.append($o);
				$container.append($frmGroup);

				if (def['showIf']) {
					root.addRule(key, def['showIf']);
				}
	
				if (def['generated']) {
					root.addGen(key, def['generated']);
				}

			}

			root.container = $container;
		});
	}

	this.dependsOn = function (k) {
		var def = root.defs[k];
		if (!def['generated']) {
			return []
		} else {
			return def['generated']['variables'];
		}
	}

	this.checkOnLoad = function () {
		var loaded = true;

		$.each(fields, function (k, v) {
			if (!v.isLoaded) {
				loaded = false;
			}
		});

		if (loaded) {
			$(window).trigger("gv:formload");
		}
		return loaded;
	}

	this.add = function (key, $field, value) {
		/*
		Add a field to the form with <name=key>
		$field is a jQuery object
		*/
		$field.on('change', function (e) {
			if ($field.hasClass("location")) {
				vars[key] = $field.locationpicker("location");
			} else if ($field.hasClass("input-group")) {
				vars[key] = $field.find(`[name=${key}]`).val();
			} else {
				vars[key] = $field.val();
			}
			root.executeGens(e);
		});
		fields[key] = $field;

		if (value) {
			root.val(key, value);
		}

	};

	this.get = function (key) {
		/*
		Retrieve a field from the record
		*/
		return fields[key];
	}

	this.values = function () {
		return vars;
	}

	this.serialize = function () {
		var all = [];
		var exclusion_list = [];

		$.each(root.defs, function (k, v) {
			if (k == undefined) { return; }

			/* Please see TransportRecord::Mode - if editable is used as a basis for serialization then hidden fields are not being saved */
			if (v.disabled === true) { return; }
			if (v.type == 'file') { return; }	// Ignore file inputs in case of serialize
			if (v.type == 'image') { return; }	// Ignore file inputs in case of serialize

			if (!root.get(k)) { 
				console.log(`Unable to serialize: ${k} - no field found. Check type: ${v.type}`)
				return; 
			}
			var o = root.get(k).closest(".form-group");
			if (o.hasClass('gv-showIf-hide')) { return; }

			var vv = root.val(k);

			if (vv != undefined) {
				/*
				// OrderShipment::AALicenses used to require this but since we are saving via the PUT method, this is not needed
				if (vv === Object(vv)){
					vv = JSON.stringify(vv);
				}*/
				all.push({ name: k, value: vv });
			}
		});
		return all;
	}

	this.validate = function () {
		// Reset the error state
		root.container.find('.form-group,td').removeClass('has-error');
		root.container.find("[aria-invalid=true]").removeAttr("aria-invalid");
		refreshAlerts([]);

		var errorState = false;

		$.each(root.defs, function (k, v) {
			if (k == undefined) { return; }
			if (v.disabled === true) { return; }
			if (v.editable === false) { return; }
			if (v.type == 'file') { return; }	// Ignore file inputs in case of serialize
			if (v.type == 'image') { return; }	// Ignore file inputs in case of serialize

			if (v.required == true) {
				var vv = root.val(k);

				if ((vv == undefined) || (vv.length == 0)) {
					var f = root.get(k);
					if (!f.is(":visible")) { return; }	// e.g. showIf for OrderShipment::Liner in case of LorryReceipt

					if (f.is("select,input,textarea")) {
						f.attr("aria-invalid", "true");
					} else {
						f.find("input,textarea,select").attr("aria-invalid", "true");
					}
					f.closest(".form-group").addClass("has-error");
					errorState = true;
				}
			}
		});

		if (errorState) {
			refreshAlerts({ 'error': ["There are errors in your input - Required fields are highlighted in red"] });
		}

		return (!errorState);
	}

	this.val = function (key, value) {
		var $field = root.get(key);
		if ($field == undefined) return undefined;

		if (value == undefined) {
			// Getters
			if ($field.hasClass("gv-radio")) {
				return $field.find("input[type=radio]:checked").val();
			} else if ($field.hasClass("gv-checkbox")) {
				var $checks = $field.find("input[type=checkbox]:checked");
				var r = [];
				$.each($checks, function (i, o) {
					r.push($(o).val());
				});
				return r;
			} else if ($field.hasClass("location")) {
				return $field.locationpicker('location');
			} else if ($field.hasClass("gv-date")) {
				var value = $field.datepicker("getDate");
				if (value == null || value == undefined) {
					return "";
				}

				var v_date = window.luxon.DateTime.fromISO((new Date(value)).toISOString());
				if (v_date.isValid) {
					value = v_date.toISODate()
					return value;
				} else {
					return null;
				}
			} else if ($field.hasClass("gv-select2")) {
				// We need to return an array of ids (or a single id if multiple == false)
				var v = $field.select2("data");	// This returns a full object - that may be useful somewhere
				// However, the showIf change handlers from sureka.js:662 will do an indexOf on allowed_values
				// So it really needs something simple to work with

				v = v.map(function (o) { return o['id'] });

				if ((v.length == 1) && $field.prop('multiple') == false) {
					v = v[0];
				} else if (v.length == 0) {
					v = "";
				}
				return v;
			} else if ($field.hasClass("gv-select")) {
				var $checks = $field.find(":selected");
				if ($checks.length == 1) {
					return $field.val();
				} else {
					var r = [];
					$.each($checks, function (i, o) {
						r.push($(o).attr('value'));
					});
					return r;
				}
			} else if ($field.hasClass("gv-table")) {
				return JSON.parse($field.tableInput('serializeJSON'));
			} else if ($field.hasClass("gv-object-contents")) {
				let object_vars = [];
				$field.find('*[class*=gv-]').each(function (i, o) {
					var n;

					if ($(o).hasClass("gv-checkbox")) {
						n = $(o).find("input[type=checkbox]").attr('name');
						n = (n.endsWith("[]")) ? n.slice(0, -2) : n;
					} else {
						n = $(o).attr('name');
						if (!n || n.trim().length == 0) { return; }
					}

					object_vars.push({
						'name': n,
						'value': root.val(n)
					});
				});
				return object_vars;
			} else if ($field.hasClass("gv-text")) {
				var v;
				if ($field.find("input[type=hidden]").length > 0) {
					v = $field.find("input[type=hidden]").val();
				} else {
					v = $field.val();
				}
				return v;
			} else if ($field.hasClass("gv-wysiwyg")) {
				var editor = $field.data('editor');
				return editor.getData();
			} else if ($field.hasClass("gv-code")) {
				var jar = $field.data('code');
				return jar.toString();
			} else {
				return $field.val();
			}

		} else {
			// Setters
			vars[key] = value;

			if ($field.hasClass("gv-radio")) {
				$field.find("input[type=radio]").filter("[value='" + value + "']").prop('checked', true);
				$field.find("input[type=radio]").trigger('change');
			} else if ($field.hasClass("gv-checkbox")) {
				var $checks = $field.find('input[type=checkbox]');
				$checks.prop('checked', false);
				if ($.isArray(value)) {
					$.each(value, function (iii, ooo) {
						$checks.filter("[value='" + ooo + "']").prop('checked', true);
					});
				} else {
					$checks.filter("[value='" + value + "']").prop('checked', true);
				}
				$checks.trigger('change');
			} else if ($field.hasClass("location")) {
				if (!!value) {
					value['latitude'] = value['latitude'] || value['lat'];
					value['longitude'] = value['longitude'] || value['lon'] || value['lng'];

					if (value['latitude'] && value['longitude']) {
						$field.locationpicker('location', value);
						$field.trigger('change');
					}

				}
			} else if ($field.hasClass("gv-date")) {
				var v_date = luxon.DateTime.fromISO(value)
				if (v_date.isValid) {
					value = v_date.toISODate();
					$field.datepicker("setDate", new Date(value));
				}
			} else if ($field.hasClass("gv-select2")) {
				var addIfNotExists = function (id, text, selected) {
					if ($field.find("option[value='" + id + "']").length) {
						$field.val(id);
					} else {
						// Create a DOM Option and pre-select by default
						var newOption = new Option(text, id, selected, selected);
						// Append it to the select
						$field.append(newOption);
					}
				};

				if (value instanceof Array) {
					$.each(value, function (i, o) {
						addIfNotExists(o.id, o.text, true);	// The selected parameter needs to be true for multiple selection e.g. OrderShipment->AALicense
					});
				} else if (typeof value == 'object') {
					addIfNotExists(value.id, value.text, true);
				} else if ($field.attr("ajax")) {
					var url = $field.attr('ajax');
					var glue = (url.indexOf("?") > -1) ? "&" : "?";
					$.ajax({
						'type': 'GET',
						'url': url + glue + "id=" + value,
						'dataType': 'json'
					}).then(function (data) {
						data = data['items'];
						addIfNotExists(data.id, data.text, true);
					});
				} else {
					$field.val(value);
				}
				$field.trigger('change');
			} else if ($field.hasClass("gv-select")) {
				$field.find('option').prop('selected', false);

				if ($.isArray(value)) {
					$.each(value, function (ii, oo) {
						$field.find("option").filter("[value='" + oo + "']").prop('selected', true);
					});
				} else if ($.isPlainObject(value) && value['id']) {
					$field.find("[value='" + value['id'] + "']").prop('selected', true);
				} else {
					$field.find("option[value='" + value + "']").prop('selected', true);
				}
				$field.trigger('change');
			} else if ($field.hasClass('gv-table')) {
				// This is if the table is simple
				if ((typeof value) == 'string') {
					if (value.length > 0) {
						value = JSON.parse(value);
					} else {
						value = [];
					}
				}
				$field.tableInput('setData', value);

			} else if ($field.hasClass("gv-object-contents")) {
				$.each(value, function (k, v) {
					root.val(k, v);
				});
			} else if ($field.hasClass("gv-dropzone")) {
				// Create the mock file:
				let $inp = $field.get(0).dropzone;
				$.each(value, function (k, v) {
					var mockFile = { name: k, size: v['contentLength'], dataURL: v['url'] };
					$inp.emit("addedfile", mockFile);

					if (v['thumbnail']) {
						$inp.emit("thumbnail", mockFile, v['thumbnail']);
					}

					$inp.emit("complete", mockFile);
				});
			} else if ($field.hasClass("gv-text")) {
				if ($field.find("input[type=hidden]").length > 0) {
					$field.find("input[type=hidden]").val(value).trigger("change");
				} else {
					$field.val(value).trigger("change");
				}
			} else if ($field.hasClass("gv-wysiwyg")) {

				$field.data('editor').setData(value);
				$field.trigger('change');

			} else if ($field.hasClass("gv-code")) {

				$field.data('code').updateCode(value);
				$field.trigger('change');

			} else if ($field.attr('type') == "hidden") {
				if ($.isPlainObject(value) && value.hasOwnProperty('id')) {
					$field.val(value['id']);
				} else {
					$field.val(value);
				}
				$field.trigger('change');
			} else {
				$field.val(value);
				$field.trigger('change');
			}


		}

	}

	this.construct(options);
	this.addRule = function (destination_e, r) {
		/*
		We need to do this after onload because
		window.SurekaForm.get(destination_e)
		is not available until after onload - locationpicker, datatables, select2 etc. etc.
		*/

		$.each(r, function (element_name, allowed_values) {
			$(document).on("change", "[name^=" + element_name + "]", function () {
				var to_hide = false;
				// Each time one element changes, we need to check all the element which destination_e depends on
				$.each(r, function (n, allowed_values) {
					let v = root.val(n);
					//v = vars[n];	// We can't use this because ItemQuote->TransportSpec->IncotermId does not have a value in vars but is set to EXW on the UI
					var show = false;
					if ($.isArray(v)) {
						show = allowed_values.filter(x => v.includes(x)).length > 0;
					} else {
						show = !(allowed_values.indexOf(v) == -1);
					}

					to_hide = to_hide || !show;
				});

				var o = root.get(destination_e);
				o = $(o).closest('.form-group');
				(to_hide) ? o.hide().addClass('gv-showIf-hide') : o.show().removeClass('gv-showIf-hide');
			});
		});

	};

	this.addGen = function (destination_e, r) {
		$.each(r['variables'], function (i, variable) {
			if (!gens[variable]) {
				gens[variable] = {};
			}

			var remote = r['remote'];
			if (!gens[variable][remote]) {
				gens[variable][remote] = {
					'variables': r['variables'],
					'destinations': []
				}
			}
			gens[variable][remote]['destinations'].push(destination_e);

		});
	}

	this.getDefinitions = function () {
		return root.defs;
	};

	this.executeGens = function (e) {
		var destination_e = $(e.target).attr('name');

		if (($(e.target)).hasClass("gv-filer-group") && (destination_e == undefined)) { return; } // In case of file input dropzone which is not initialized, we should do nothing

		destination_e = (destination_e.substr(0, 2) == "d_") ? destination_e.substr(2) : destination_e;
		var current_value = root.val(destination_e); //$(e.target).val();

		if (gens[destination_e]) {
			let defs = gens[destination_e];
			$.each(defs, function (url, def) {
				var data = {};
				$.each(def['variables'], function (i, v) {
					data[v] = vars[v];
				});
				$.getJSON(url, data, function (response) {

					let procFn = function (k, v) {
						//if (typeof v != 'object') return;
						var ctrl = null;
						if (k == 'items' && def['destinations'].length == 1) {
							ctrl = root.get(def['destinations'][0]);
						} else if (def['destinations'].indexOf(k) > -1) {
							ctrl = root.get(k);
						}

						if (ctrl != null) {
							if (ctrl.hasClass('gv-select2') || ctrl.hasClass('gv-select')) {
								options = [];
								if (v instanceof Array) {
									$.each(v, function (i, e) {
										// Do not select by default - doesn't make sense since there are multiple
										// items in v anyway. See Location->State depends on Location->Country
										options.push($("<option value='" + (e.id || e.Id) + "'>" + e.text + "</option>"));
									});
									// EARLIER: Do not remove existing options - see Location->State depends on Location->Country
									// 2019-08-05 Have tested this for Location->State as well as OrderShipmentFreightRate->Station and it seems to work ok with the remove
									ctrl.find("option").remove();
									ctrl.append(options).trigger('change');
									ctrl.trigger({
										type: 'select2:select',
										params: { data: v }
									});
								} else if (typeof v == "object" && v !== null) {
									options.push($("<option selected value='" + v.id + "'>" + v.text + "</option>"));
									ctrl.append(options).trigger('change');
									ctrl.trigger({
										type: 'select2:select',
										params: { data: v }
									});
								} else if (ctrl.attr('ajax') && v !== null) {
									var ajax_url = ctrl.attr('ajax');
									ajax_url += ((ajax_url.indexOf("?") ? "&" : "?") + 'id=' + v);
									var promised_ctrl = ctrl;
									$.ajax({
										'type': 'GET',
										'url': ajax_url,
										'dataType': 'json'
									}).then(function (data) {
										if (data['items']) {
											data = data['items'];
											var option = new Option(data.text, data.id, true, true);
											promised_ctrl.append(option).trigger('change');
											promised_ctrl.trigger({
												type: 'select2:select',
												params: { data: data }
											});
										}
									});
								} else if (v !== null) {
									ctrl.val(v);
									ctrl.trigger('change');
									ctrl.trigger({
										type: 'select2:select',
										params: { data: v }
									});
								}

							} else if (ctrl.hasClass("table")) {
								ctrl.tableInput("setData", v);
								ctrl.trigger("change");
							} else if (ctrl.hasClass('gv-container') || ctrl.hasClass('gv-object-contents')) {
								ctrl.html(v);
								ctrl.trigger("change");
							} else if (ctrl.hasClass('location')) {
								v['latitude'] = v['latitude'] || v['lat'];
								v['longitude'] = v['longitude'] || v['lng'];
								if (!!v['latitude'] && !!v['longitude']) {
									ctrl.locationpicker('location', v);
									ctrl.trigger('change');
								}
							} else {

								ctrl.val(v);
								ctrl.trigger('change');
							}
						}
					};

					$.each(response, procFn);
					$.each(response['result'] || [], procFn);
				});
			});
		}
	};

}

var refreshAlerts = function (sureka_alerts) {
	if ($('#_alerts').length == 0) {
		$("body").prepend('<div id="_alerts" aria-live="polite" aria-atomic="true" style="position: fixed !important; top: 1em; right: 1em; z-index:10000;overflow:visible !important"></div>');
	}

	var p = $('#_alerts');
	p.find(".toast").remove();
	var toast = null;
	var icon_map = {
		'success': '<i class="mr-2 fa fa-check-circle text-success"></i>',
		'error': '<i class="mr-2 fa fa-times-circle text-danger"></i>',
		'warning': '<i class="mr-2 fa fa-exclamation-triange text-warning"></i>',
		'info': '<i class="mr-2 fa fa-info-circle text-info"></i>',
	};

	var title_map = {
		'success': 'Confirmation',
		'error': 'Error',
		'warning': 'Warning!',
		'info': 'Information',
	}
	$.each(sureka_alerts, function (k, v) {
		var kk = (k == "error") ? "danger" : k;
		if ((!!v) && (v.length > 0)) {
			$.each(v, function (i, e) {
				toast = `<div class="toast border-${kk}" role="alert" aria-live="assertive" aria-atomic="true" data-delay="5000" style="min-width:20em;font-size:100%;border-width:2px;">
							<div class="toast-header">
								${icon_map[k]}
								<strong class="mr-auto">${title_map[k]}</strong>
								<small class="text-muted">${luxon.DateTime.now().toFormat('h:mm:ss a')}</small>
								<button type="button" class="ml-2 mb-1 close" data-dismiss="toast" aria-label="Close">
									<span aria-hidden="true">&times;</span>
								</button>
							</div>
							<div class="toast-body">
								${e}
							</div>
						</div>`;
				p.append(toast);
			});
		}
	});

	$(".toast").toast('show');
}

$(document).ajaxComplete(function (e, jqXHR) {
	if (jqXHR.getResponseHeader('X-Grapevine-Login') === "1") {
		window.location.replace("/login.php");
	}
});

$(function () {
	var $a = $("script[name=_alerts]");
	if ($a.length > 0) {
		window.sureka_alerts = JSON.parse($a.html());
	}
	refreshAlerts(window.sureka_alerts);
});

$(document).on("gv:login", function () {
	var l = document.createElement("a");
	l.href = window.location.href;
	if (l.pathname == "/login.php") {
		return;
	}
	alert("Your session has been logged out. Please log in again");
	window.location.replace("/login.php?r=0");
});

// nowrap can be used on td elements to neatly show ellipses
$(function () {
	$(".nowrap,.nowrap-inline").each(function (i, e) {
		$(e).attr("title", $(e).text().trim());
	});
});

var initTable = function (o, varname, opts) {
	if (!o.length) { return; }

	if ($.fn.dataTable) {
		$.fn.dataTable.ext.errMode = 'none';
		$.fn.DataTable.ext.pager.numbers_length = 9;
	} else {
		return null;
	}

	$.extend(DataTable.ext.classes, {
		sFilterInput: "form-control w-100 text-left m-0 mb-3 mb-md-0"
	});

	if ($(o).hasClass("class-table") && $(o).hasClass("list-table")) {
		$(o).find("thead tr").clone(true).appendTo($(o).find("thead"));
		$(o).find("thead tr:eq(1) th:not(:last)").each(function (i) {
			if (!$(this).get(0).hasAttribute('searchable')) {
				$(this).html('<span>&nbsp;</span>');
				return;
			}
			var title = $(this).text();
			$(this).html('<input type="text" placeholder="Search ' + title + '" class="form-control"/>');

			$("input", this).on('keyup change', function () {
				if (this.value.length < 3) { return; }
				var table = window[varname];
				if (table && table.column(i).search() !== this.value) {
					table.settings()[0].jqXHR.abort();
					table.column(i).search(this.value).draw();
				}
			});
		});
	}

	var options = {
		scrollY: '35vh',
		scroller: {
			loadingIndicator: true
		},
		scrollCollapse: true,
		deferRender: true,
		responsive: true,
		paging: false,
		language: {
			search: "",
			searchPlaceholder: "Search by ID or text"
		}
	};

	$.extend(options, opts);

	if ($(o).attr('ajax')) {
		$.extend(options, {
			processing: true,
			serverSide: true,
			ajax: $(o).attr('ajax'),
			searchDelay: 2000
		});
	}

	if ($(o).attr('paging') && $(o).attr('paging') != 'false') {
		$.extend(options, {
			paging: true,
			pagingType: "full_numbers",
			pageLength: 50,
			lengthChange: true,
			lengthMenu: [50, 100, 200, 'All']
		});
	}

	if ($(o).attr('buttons')) {
		var button_map = {
			'output_csv': {
				text: 'Download CSV', action: function (e, dt, button, config) {
					var url = dt.ajax.url();
					if (url) {
						url += ((url.indexOf("?") > -1) ? "&" : "?");
						let params = dt.ajax.params();
						params['length'] = -1;
						url += "output=csv&" + $.param(params);
						window.location.href = url;
					} else {
						alert("CSV download not available. Please raise a ticket");
					}
				}
			}
		}

		var buttons = $(o).attr('buttons').split(' ').map(function (x) {
			if (button_map[x]) {
				return button_map[x];
			}
			return x;
		});

		$.extend(options, {
			dom: '<"gv-table-controls row"<"col-lg-7 col-xl-8"f><"col-lg-5 col-xl-4 text-center"B>>rt<"gv-table-controls row"<"col-lg-3 col-xl-2"l><"col-lg-2 col-xl-2"i><"col-lg-7 col-xl-8"p>>',
			buttons: buttons,
		});
	}

	var columns = [];
	// Properly handle multiple rows inside thead
	let th_list = ($(o).find("thead tr").length > 1) ? $(o).find("thead tr:last th") : $(o).find("thead th");
	th_list.each(function (ii, oo) {
		if (columns[ii]) {
			columns[ii]['className'] = oo.className;
		} else {
			columns.push({ 'className': oo.className })
		}
	});
	options['columns'] = columns;
	options['orderCellsTop'] = true;

	if (o.DataTable) {
		o.on('error.dt', function (e, settings, techNote, message) {
			message = message.substring(message.indexOf('##') + 2);
			Alerts.addError(message)._render();
		});

		var opt = options;
		$(function () {
			if (varname) {
				window[varname] = o.DataTable(opt);
			} else {
				o.DataTable(opt);
			}
		});
	}
}

$("table.table").each(function (i, o) {
	let $o = $(o);
	let n = $o.attr("name") || null;
	if ($o.hasClass("disabled")) {
		return;
	}
	initTable($o, n, $o.data());

});

String.prototype.hashCode = function () {
	var hash = 0, i, chr;
	if (this.length === 0) return hash;
	for (i = 0; i < this.length; i++) {
		chr = this.charCodeAt(i);
		hash = ((hash << 5) - hash) + chr;
		hash |= 0; // Convert to 32bit integer
	}
	return hash;
};

$("table.gv-report").each(function (i, o) {
	var p = $(o).attr("parameters");
	if (!p) { return; }

	$(p).find(".gv-date").each(function (ii, oo) {
		var opts = { format: 'dd/mm/yyyy' };
		const d = new Date($(oo).val());
		$(oo).datepicker(opts).datepicker('setDate', d);
	});

	$(p).find(".gv-select2").each(function (ii, oo) {
		$.fn.select2.defaults.set("width", "100%");
		var options = {
			escapeMarkup: function (markup) { return markup; }, // let our custom formatter work
			minimumInputLength: 1
		};
		if ($(oo).attr('ajax')) {
			$.extend(options, {
				escapeMarkup: function (markup) { return markup; }, // let our custom formatter work
				minimumInputLength: 3,
				ajax: {
					url: $(oo).attr('ajax'),
					dataType: 'json',
					delay: 250,
					placeholder: 'Select one...',
					data: function (params) {
						return {
							q: params.term,	// search term
							page: params.page
						};
					},
					processResults: function (data, params) {
						params.page = params.page || 1;

						return {
							results: data.items,
							pagination: {
								more: (params.page * 30) < data.total_count
							}
						};
					},
					cache: true
				}
			});
		}
		$(oo).select2(options);
	});

	$(p).find('input, select').change(function () {
		var arr = $(p).find('input, select').map(function (ii, oo) {
			var v;
			if ($(oo).hasClass("gv-date")) {
				v = $(oo).datepicker("getDate");
				v = (window.luxon.DateTime.fromISO((new Date(v)).toISOString()).isValid) ? (new Date(v)).toISOString() : null;
			} else if ($(oo).hasClass("gv-select2")) {
				v = '';
				var d = $(oo).select2("data");
				if (d && d.length > 0) {
					v = d[0]['Id'] || d[0]['id'];
				}
			} else {
				v = $(oo).val();
			}
			return {
				'name': $(oo).attr('name'),
				'value': v
			};
		});
		console.log(arr);
		var report = window[$(o).attr('name')];
		var $a = $("<a></a>").attr("href", report.ajax.url()).get(0);
		var xurl = $a.pathname + "?" + $.param(arr);
		report.ajax.url(xurl).load();
	});

	// Look for url parameters
	$(function () {
		var sPageURL = window.location.search.substring(1),
			sURLVariables = sPageURL.split('&'),
			sParameterName,
			i,
			$inp;

		for (i = 0; i < sURLVariables.length; i++) {
			sParameterName = sURLVariables[i].split('=');

			$inp = $(p).find("[name='" + sParameterName[0] + "']");
			if ($inp.length > 0) {
				$inp.val(sParameterName[1] === undefined ? true : decodeURIComponent(sParameterName[1]));
				$inp.trigger('change');
			}
		}
	});
});

var BarcodeHandler = {
	handlerRegistered: false,
	eventMap: {},

	registerHandler: function () {
		if (BarcodeHandler.handlerRegistered) return;

		$(function () {
			window.barcodeTimer = 0;
			window.barcodeAcc = "";

			$(document).on('keypress', function (e) {
				let t = Date.now();

				if ((t - window.barcodeTimer) > 10000) {	// Set this to be 10000
					window.barcodeTimer = t;
					window.barcodeAcc = "";
				}
				console.log(e.key);
				if (e.key == "Enter") {

					var result = /[A-Z]{3}[a-z0-9]{11}/.test(window.barcodeAcc);
					console.log(window.barcodeAcc, window.barcodeAcc.length, result);
					if (window.barcodeAcc.length == 14 && result) {
						// Get the object from the server
						var barcode = window.barcodeAcc;
						$.getJSON('/ui/code.php?d=' + window.barcodeAcc, function (response) {
							$(window).trigger("gv-barcode:" + barcode.substr(0, 3), response);
						});
					}
					window.barcodeAcc = "";
				} else {
					window.barcodeAcc += e.key;
				}

			})
		});
		BarcodeHandler.handlerRegistered = true;
	},

	_registerEvent: function (barcode_prefix) {
		if (BarcodeHandler.eventMap[barcode_prefix]) return;

		let d = $(window).on("gv-barcode:" + barcode_prefix, function (e, response) {

			if (response.status == "error") {
				window.alert(response.message);
				return;
			}

			$.each(BarcodeHandler.eventMap[barcode_prefix]['subscribers'], function (i, o) {
				let sel = o.ref;
				if (typeof sel == "function") {
					sel(response.data);
				} else {
					let $el = $(sel);
					if ($el.hasClass("select2") && ($el.data('select2') != undefined)) {

						var option = new Option(response.data.text, response.data.id || response.data.Id, false, true);

						$el.append(option);
						$el.find('option:selected').data("gv-barcode", response.data);
						$el.trigger('change');

						$el.trigger({
							type: 'select2:select',
							params: {
								data: response.data
							}
						});
						$el.select2("close");
					} else {
						$el.data('gv-barcode', response.data);
						$el.trigger("change");
					}
				}
			});

		});

		BarcodeHandler.eventMap[barcode_prefix] = {
			'event': d,
			'subscribers': []
		};
	},

	subscribeSelect2: function (sel, barcode_prefix) {
		BarcodeHandler._registerEvent(barcode_prefix);
		let o = { 'ref': sel, 'id': Math.random().toString(16).substr(2, 14) }
		let subscribers = BarcodeHandler.eventMap[barcode_prefix]['subscribers'];
		let idx = subscribers.findIndex(k => k.ref == sel);

		if (idx == -1) {
			BarcodeHandler.eventMap[barcode_prefix]['subscribers'].push(o);
		}
		BarcodeHandler._attachSelect2Icon(sel, barcode_prefix);
		return o.id;
	},

	subscribeInput: function (sel, barcode_prefix) {
		BarcodeHandler._registerEvent(barcode_prefix);
		let o = { 'ref': sel, 'id': Math.random().toString(16).substr(2, 14) }
		let subscribers = BarcodeHandler.eventMap[barcode_prefix]['subscribers'];
		let idx = subscribers.findIndex(k => k.ref == sel);

		if (idx == -1) {
			BarcodeHandler.eventMap[barcode_prefix]['subscribers'].push(o);
		}

		return o.id;
	},

	unsubscribe: function (token, barcode_prefix) {
		let subscribers = BarcodeHandler.eventMap[barcode_prefix]['subscribers'];
		let idx = subscribers.findIndex(k => k.id == token);
		subscribers.splice(idx, 1);
		BarcodeHandler.eventMap[barcode_prefix]['subscribers'] = subscribers;
	},

	_attachSelect2Icon: function (sel, barcode_prefix) {
		$(function () {
			let r = $(sel).siblings("span.select2");
			r.find("i.fa").remove();
			r.prepend(
				'<i class="fa fa-barcode" style="float: left;font-size: 140%;margin: 0.5em 0.5em 0 0.5em;" title="' + barcode_prefix + '"></i>'
			);
		});
	}
}

// Handle master-detail UI
$(function () {
	// Handle buttons
	var fn_detail_handler = function (e) {
		let url = $(this).attr("detail");
		let id = $(this).closest('tr').data('rowid') || $(this).closest('.card-header').attr("object_id");

		let $btn = $(this);

		if ($btn.hasClass("btn-warning") || $btn.hasClass("btn-danger") || $btn.hasClass("confirm")) {
			if (!confirm("Are you sure you want to " + $btn.text())) {
				return false;
			}
		}

		e.stopImmediatePropagation();
		let detail = $(this).closest(".gv-master-detail").find("div.detail-pane");

		if ($.isFunction(detail.loadingIndicator)) {
			detail.loadingIndicator();
		}

		detail.load(url + id);
	};

	$("table.class-table tbody").on("click", "tr td:last-child *[detail]", fn_detail_handler);
	$("div.gv-master-detail").on("click", "div[name=viewbox] div.card-header *[detail]", fn_detail_handler);

	var fn_download_handler = function (e) {
		let $btn = $(this);

		if ($btn.hasClass("btn-warning") || $btn.hasClass("btn-danger") || $btn.hasClass("confirm")) {
			if (!confirm("Are you sure you want to " + $btn.text())) {
				return false;
			}
		}

		let id = $(this).closest('tr').data('rowid') || $(this).closest('.card-header').attr("object_id");
		e.stopImmediatePropagation();
		let href = $(this).attr("download");
		href = href + id;
		window.location = href;
	};

	$("table.class-table tbody").on("click", "tr td:last-child *[download]", fn_download_handler);
	$("div.gv-master-detail").on("click", "div[name=viewbox] div.card-header *[download]", fn_download_handler);

	var fn_gv_handler = function (e) {
		let $btn = $(e.target);

		var prompt_key, prompt_value = null;
		var prompt_key = $btn.attr("prompt");
		if (typeof prompt_key !== typeof undefined && prompt_key !== false) {
			// Element has this attribute
			prompt_value = prompt($btn.attr("title"));
			$btn.data(prompt_key, prompt_value);		// This will be used later
		}

		if ($btn.hasClass("btn-warning") || $btn.hasClass("btn-danger") || $btn.hasClass("confirm")) {
			if (!confirm("Are you sure you want to " + $btn.text())) {
				return false;
			}
		}

		var $row = $btn.closest('tr');
		var id = $row.data('rowid') || $row.attr('rowid');
		id = id || $(this).closest('.card-header').attr("object_id");

		if (!id) {
			alert("An error occurred. Please try again");
			const ifr = document.createElement('iframe');
			ifr.name = ifr.id = 'ifr_' + Date.now();
			document.body.appendChild(ifr);
			const form = document.createElement('form');
			form.method = "POST";
			form.target = ifr.name;
			form.action = '/assets/js/sureka.js';
			document.body.appendChild(form);
			form.submit();
			window.location.reload(true);
		}

		var method = $.grep(this.className.split(" "), function (x) {
			return x.indexOf('gv-') === 0;
		})

		method = (method.length && method[0]) ? method[0].substr(3) : null;

		var $tbl = $(this).closest("table.class-table");
		e.stopImmediatePropagation();	// Prevent the select row hander from being called

		if (method) {
			var cls = $btn.attr("className") || $tbl.attr("className") || $(this).closest('.card-header').attr("className");
			$.put('/actions/crud.json.php', JSON.stringify($.extend($btn.data(), {
				'_method': method,
				'_class': cls,
				'Id': id
			})), function (response) {
				processAlerts(response);
				if (response['status'] == 'ok' || response['status'] == 'success') {
					if ($tbl.attr("ajax")) {
						$tbl.DataTable().ajax.reload();
					}
				}
			}, 'json');
		}


	};

	// Delete function [ANY FUNCTION]
	$("table.class-table tbody").on("click", "tr td:last-child button[class*='gv-'], tr td:last-child i[class*='gv-']", fn_gv_handler);
	$("div.gv-master-detail").on("click", "div[name=viewbox] div.card-header button[class*='gv-'], div[name=viewbox] div.card-header i[class*='gv-']", fn_gv_handler);

});

var showPreview = (src, contenttype) => {
	let content = null;

	if (contenttype == "application/pdf") {
		content = $("<embed style='width:100%;height:80vh;' src='" + src + "' />");
	} else if (contenttype == "text/plain") {
		content = $('span');
		fetch(src)
			.then((res) => res.text())
			.then((text) => {
				modal.find('.modal-body').empty().html("<pre>" + text + "</pre>");
			})
	} else if (contenttype.indexOf('image') > -1) {
		content = $("<img class='img-fluid' src='" + src + "' />");
	} else if (contenttype.indexOf('audio') > -1) {
		content = $("<audio style='width:100%;' controls src='" + src + "' />");
	} else {
		content = $("<iframe style='width:100%;height:80vh;' src='https://docs.google.com/gview?embedded=true&url=" + encodeURIComponent(src) + "'><p>This browser does not support iframes</p></iframe>");
	}
	return content;
}

$(function () {

	function fallbackMessage(action) {
		var actionMsg = '';
		var actionKey = (action === 'cut' ? 'X' : 'C');
		if (/iPhone|iPad/i.test(navigator.userAgent)) {
			actionMsg = 'No support :(';
		} else if (/Mac/i.test(navigator.userAgent)) {
			actionMsg = 'Press ⌘-' + actionKey + ' to ' + action;
		} else {
			actionMsg = 'Press Ctrl-' + actionKey + ' to ' + action;
		}
		return actionMsg;
	}

	let gv_clipboard = new ClipboardJS('.gv-clipboard');

	gv_clipboard.on('success', function (e) {
		//console.log('Action:', e.action);
		//console.log('Trigger:', e.trigger);
		$(e.trigger).tooltip({ 'trigger': 'manual', 'title': 'Copied!' }).tooltip('show');
		$(e.trigger).attr('aria-label', 'Copied!');
		e.clearSelection();
	});
	gv_clipboard.on('error', function (e) {
		console.error('Action:', e.action);
		console.error('Trigger:', e.trigger);
		$(e.trigger).tooltip({ 'trigger': 'manual', 'title': fallbackMessage(e.action) }).tooltip('show');
	});

	$(document).on('blur mouseleave', ".gv-clipboard", function (e) {
		$(e.target).tooltip('hide');
	});

});

var GrapevineReport = class GrapevineReport {
	constructor(id) {
		this.id = id;
		this.c = $("#" + id);
	}
	addInputBinding(inp) {
		this.bindings = this.bindings || [];
		this.bindings.push(inp);
		inp.on('change', $.proxy(this.reload, this));
	}
	reload(e) {
		var data = {};
		var wid = null;
		var name = null;

		$.each(this.bindings, function (i, o) {
			wid = $(o).closest("gwidget").attr('id');
			name = $(o).attr("name").replace(/[\[\]]+$/, "");

			if (wid != undefined) {
				data[name] = window.widgets[wid].val(name);
			} else {
				data[name] = $(o).val();
			}
		});

		var c = this.c;

		$.ajax('/api/reports/index.php?' + this.c.attr("className"), {
			'success': function (response) {
				c.replaceWith(response);
			},
			'contentType': "application/json",
			'data': JSON.stringify(data),
			'dataType': "html",
			'method': 'PUT',
			'processData': false
		});
	}
};

var GrapevineConfig = class GrapevineConfig {
	/*
	static get(param) {
		return (async () => {
			return await localforage.getItem(param);
		})();
	}

	static set(param, value) {
		return (async () => {
			return await localforage.setItem(param, value);
		})();

	}

	static remove(param) {
		return (async () => {
			return await localforage.removeItem(param);
		})();
	}
	*/

	// We need to use cookies because the data is needed on the client AND on the server
	static get(param) {
		if (window.Cookies == undefined) return null;
		let c = Cookies.get(param);
		if (typeof c === 'string' || c instanceof String) {
			return JSON.parse(c);
		}
		return null;
	}

	static set(param, value) {
		if (window.Cookies == undefined) return null;

		Cookies.set(param, JSON.stringify(value));
	}

	static remove(param) {
		if (window.Cookies == undefined) return null;
		Cookies.remove(param);
	}

};

var async_loader = window.async_loader || [];
while (async_loader.length) { // there is some syncing to be done
	var obj = async_loader.shift();
	if (obj[0] == "ready") {
		$(obj[1]);
	} else if (obj[0] == "load") {
		$(window).load(obj[1]);
	}
}
async_loader = {
	push: function (param) {
		if (param[0] == "ready") {
			$(param[1]);
		} else if (param[0] == "load") {
			$(window).load(param[1]);
		}
	}
};